/* Variables para personalización */
:root {
  --error-color: #ffcc00;
  --error-bg-color: #fff8e1;
  --survey-max-height: 600px;
}

/* Quitar el borde rojo en campos requeridos */
.sv-question--error {
  border: none !important;
}

/* Aplicar un estilo personalizado cuando un campo es requerido pero no completado */
.custom-required {
  border: 1px solid var(--error-color); /* Amarillo */
  background-color: var(--error-bg-color); /* Fondo amarillo claro */
}

/* Mensaje de error personalizado */
.sv-question__erbox {
  color: var(--error-color) !important; /* Amarillo */
  font-style: italic;
}

/* Ocultar el mensaje "response required" */
.sd-error {
  display: none !important;
}

.sd-element--with-frame.sd-question--error-top {
   padding-top: 32px; 
}

.sd-body.sd-body--static{
  padding-top: 10px; 
}

/* En tu archivo CustomSurvey.css */
.sv-components-row {
  max-height: var(--survey-max-height); 
  overflow-y: auto; 
  margin-top: 6px; 
}

/* Wrapper para centrar correctamente el survey */
.survey-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Asegurar que el formulario se ajuste correctamente en dispositivos móviles */
@media screen and (max-width: 767px) {
  :root {
    --survey-max-height: 400px;
  }
  
  .sv-root-modern {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .sv-question {
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 12px;
  }
  
  .sv-question__title {
    font-size: 16px;
    line-height: 1.3;
  }
  
  /* Asegurar que los elementos del formulario sean responsivos */
  .sv-question__content {
    max-width: 100%;
    margin: 0 auto;
  }
  
  /* Hacer que los elementos input sean responsivos */
  .sv-text {
    width: 100%;
  }
  
  input, select, textarea {
    font-size: 16px;
  }
  
  /* Centrar el contenido de Survey.js */
  .sv-container-modern, 
  .sv-body,
  .sv-body__page,
  .sv-page,
  .sv-row {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  
  .popup-inner .sv-body__page {
    width: 100%;
  }
}

/* Ajustes para tablets */
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sv-root-modern {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
}

/* Para pantallas grandes, mantener ancho adecuado */
@media screen and (min-width: 1021px) {
  .popup-inner .sv-root-modern {
    min-width: 500px;
  }
}