/* Variables para Modal.css */
:root {
  --modal-z-index: 2000;
  --modal-bg-color: rgba(0, 0, 0, 0.5);
  --modal-content-bg: white;
  --modal-border-radius: 5px;
  --modal-padding: 20px;
  --modal-width-desktop: 500px;
  --modal-width-mobile: 90vw;
}

/* src/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--modal-z-index);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
}

.modal-content {
  background: var(--modal-content-bg);
  padding: var(--modal-padding);
  border-radius: var(--modal-border-radius);
  width: var(--modal-width-mobile);
  max-width: var(--modal-width-desktop);
  box-sizing: border-box;
}

.modal-content-duplicate {
  background: var(--modal-content-bg);
  padding: var(--modal-padding);
  border-radius: var(--modal-border-radius);
  width: 80%;
  display: flex;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group small.field-description {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 0.9em;
}

/* Full-screen image modal */
.image-modal {
  width: 90%;
  max-width: none;
  max-height: 90vh;
  padding: 0;
  background: transparent;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
}

/* Estilos específicos para dispositivos móviles */
@media screen and (max-width: 767px) {
  .modal-content {
    width: 95%;
    max-width: 95vw;
    padding: 15px;
  }
  
  .form-group label {
    font-size: 16px;
  }
  
  .form-group input {
    font-size: 16px;
    padding: 10px;
  }
  
  .form-group small.field-description {
    font-size: 0.85em;
  }
}

/* Estilos para tablets */
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .modal-content {
    width: 80%;
    max-width: 700px;
  }
}

/* Pantallas grandes */
@media screen and (min-width: 1021px) {
  .modal-content {
    width: 90%;
    max-width: var(--modal-width-desktop);
  }
}